import React, { useEffect, useState } from 'react'
import { Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Account/Account.module.scss'
import { FormComponent } from '../../shared'
import { LinkUtil } from '../../shared/link'
import history from '../../history'
import { useLinkState } from '../../contexts/link'

export const Funnel = ({ route }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [data, setData] = useState({})
	const [isLoading, setIsLoading] = useState(false)

	const linkState = useLinkState()

	useEffect(() => {
		if (!linkState.isInitialized) return

		const id = getFunnelId()

		if (id) {
			setIsEditing(true)
			getFunnel(id).then(response => {
				setData(response)
				setIsLoading(false)
			})
		}
	}, [linkState.isInitialized])

	const getFunnelId = () => {
		const id = route.match.params.id || null

		return !isNaN(id) ? parseInt(id) : null
	}

	const getFunnel = async id => {
		const linkUtil = new LinkUtil()
		setIsLoading(true)

		const { status: responseStatus, data } = await linkUtil.getFunnel(id)

		if (responseStatus !== 200) {
			toastMessage(data.message || 'An error has been occurred', 'error')
			return
		}

		const {
			link_funnel_id,
			link_product_id,
			name,
			funnelflux_id,
			kpis,
			fk,
			sfk,
			status
		} = data.data

		const product = findProduct(link_product_id)

		return {
			link_funnel_id,
			link_product_id,
			name,
			status,
			funnelflux_id,
			kpis,
			fk,
			sfk,
			product: product
		}
	}

	function handleSave() {
		const linkUtil = new LinkUtil()
		const id = getFunnelId()

		setIsLoading(true)

		const payload = {
			link_product_id: data?.product?.value,
			name: data.name,
			funnelflux_id: data.funnelflux_id,
			kpis: data.kpis,
			status: data.status,
			fk: data.fk,
			sfk: data.sfk
		}

		if (id) {
			linkUtil.updateFunnel(id, payload).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error || 'An error has been occurred', 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Funnel updated successfully', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		} else {
			linkUtil.createFunnel(payload).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error || 'An error has been occurred', 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Funnel created successfully', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		}
	}

	function findProduct(productId) {
		if (!productId || !linkState.isInitialized) {
			return {}
		}
		const index = linkState.data.link.products?.findIndex(
			product => product.id === parseInt(productId)
		)

		const product = linkState.data.link.products[index]

		return {
			value: product?.id,
			label: product?.name
		}
	}

	const statusOptions = [
		{
			value: 'active',
			label: 'Active'
		},
		{
			value: 'inactive',
			label: 'Inactive'
		}
	]

	const form = () => {
		const options = {
			product: {
				label: 'Product',
				props: {
					isDisabled: !!isEditing,
					type: 'select',
					value: data.product,
					onChange: e => {
						setData({
							...data,
							product: e
						})
					}
				},
				options: linkState.data.link.products?.map(product => ({
					value: product.id,
					label: product.name
				}))
			},
			name: {
				label: 'Name',
				props: {
					type: 'text',
					placeholder: 'Name',
					value: data.name,
					maxLength: 64,
					onChange: e => {
						setData({
							...data,
							name: e.target.value
						})
					}
				}
			},
			funnelflux_id: {
				label: 'Funnelflux ID',
				props: {
					type: 'text',
					placeholder: 'Funnelflux ID',
					value: data.funnelflux_id,
					onChange: e => {
						setData({
							...data,
							funnelflux_id: e.target.value
						})
					}
				}
			},
			kpis: {
				label: 'KPIs',
				props: {
					type: 'text',
					placeholder: 'KPIs',
					value: data.kpis,
					onChange: e => {
						setData({
							...data,
							kpis: e.target.value
						})
					}
				}
			},
			status: {
				label: 'Status',
				props: {
					type: 'select',
					value: statusOptions.find(option => option.value === data.status),
					onChange: e => {
						setData({
							...data,
							status: e.value
						})
					}
				},
				options: statusOptions
			},
			fk: {
				label: 'Funnel Key',
				props: {
					type: 'text',
					pattern: '[0-9a-z_]+',
					title: 'Only lowercase letters, numbers and underscores are allowed',
					placeholder: 'FK',
					value: data.fk,
					maxLength: 32,
					onKeyDown: e => {
						if (!e.key.match(/[0-9a-z_]+/)) {
							e.preventDefault()
						}
					},
					onChange: e => {
						setData({
							...data,
							fk: e.target.value
						})
					}
				}
			},
			sfk: {
				label: 'Sub Funnel Key',
				props: {
					type: 'text',
					placeholder: 'SFK',
					value: data.sfk,
					maxLength: 32,
					onKeyDown: e => {
						if (!e.key.match(/[0-9a-z_]+/)) {
							e.preventDefault()
						}
					},
					onChange: e => {
						setData({
							...data,
							sfk: e.target.value
						})
					}
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: handleSave
				}
			}
		}

		const form = new FormComponent(options)

		return (
			<>
				{form.renderInput('product')}
				{form.renderInput('name')}
				{form.renderInput('funnelflux_id')}
				{form.renderInput('kpis')}
				{form.renderInput('status')}
				{form.renderInput('fk')}
				{form.renderInput('sfk')}
				{form.renderButton('save', { loading: isLoading })}
			</>
		)
	}

	return (
		<Page
			render={() => {
				if (!linkState?.isInitialized) {
					return <Loader display={true} />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								{isEditing ? (
									<h3>
										Editing Funnel #{getFunnelId()}:{data.name}
									</h3>
								) : (
									<h3>Creating Funnel</h3>
								)}
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>{form()}</div>
								</div>
							</div>
						</div>
						<Loader display={isLoading}></Loader>
					</>
				)
			}}
		></Page>
	)
}
