import React from 'react'
import { TabBar } from '../../components/TabBar/TabBar'
import { TRAFFIC_SOURCE_TYPE_LIST } from './utils'
import mainStyles from '../../assets/css/App.module.scss'

export const TrafficSourceTypes = ({ handleChange, selectedTab }) => {
	return (
		<TabBar>
			{TRAFFIC_SOURCE_TYPE_LIST.map(linkTypeItem => {
				const itemStyles = [mainStyles.tab]

				if (selectedTab === linkTypeItem.id) itemStyles.push(mainStyles.active)

				return (
					<button
						onClick={handleChange.bind(this, linkTypeItem.id)}
						key={linkTypeItem.id}
						className={itemStyles.join(' ')}
					>
						{linkTypeItem.label}
					</button>
				)
			})}
		</TabBar>
	)
}
