import React, { useState } from 'react'
import { Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Account/Account.module.scss'
import { FormComponent } from '../../shared'
import { LinkUtil } from '../../shared/link'
import history from '../../history'

export const Product = ({ route }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [data, setData] = useState({})
	const [isLoading, setIsLoading] = useState(false)

	async function preFetch() {
		const id = getProductId()
		let returnData = {}
		setIsLoading(true)

		if (id) {
			setIsEditing(true)
			const product = await getProduct(id)

			setData(product)
		}

		setIsLoading(false)

		return returnData
	}

	const getProductId = () => {
		const id = route.match.params.id || this._id

		return !isNaN(id) ? parseInt(id) : null
	}

	const getProduct = async id => {
		const linkUtil = new LinkUtil()

		const { status, data } = await linkUtil.getProduct(id)

		if (status !== 200) {
			console.log(data.message)
			return
		}

		const { link_product_id, name, pk } = data.data

		return {
			name,
			pk,
			link_product_id
		}
	}

	function handleSave() {
		const linkUtil = new LinkUtil()
		const id = getProductId()

		setIsLoading(true)

		if (id) {
			linkUtil.updateProduct(id, data).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error || 'An error has been occurred', 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Product updated', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		} else {
			linkUtil.createProduct(data).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error, 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Product created', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		}
	}

	const getForm = () => {
		const options = {
			name: {
				label: 'Name',
				props: {
					type: 'text',
					placeholder: 'Name',
					required: true,
					value: data.name,
					maxLength: 32,
					onChange: e => {
						setData({
							...data,
							name: e.target.value
						})
					}
				}
			},
			pk: {
				label: 'Product Key',
				props: {
					type: 'text',
					placeholder: 'PK',
					required: true,
					value: data.pk,
					maxLength: 32,
					onChange: e => {
						setData({
							...data,
							pk: e.target.value
						})
					}
				}
			},
			marketplace_ids: {
				label: 'Marketplace IDs',
				props: {
					type: 'text',
					placeholder: 'Marketplace IDs',
					value: data.marketplace_ids,
					maxLength: 255,
					onChange: e => {
						setData({
							...data,
							marketplace_ids: e.target.value
						})
					}
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: handleSave
				}
			}
		}

		return new FormComponent(options)
	}

	return (
		<Page
			preFetch={preFetch}
			render={() => {
				const form = getForm()

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								{isEditing ? (
									<h3>
										Editing Product #{getProductId()}: {data.name}
									</h3>
								) : (
									<h3>Creating product</h3>
								)}
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>
										{form.renderInput('name')}
										{form.renderInput('pk')}
										{form.renderInput('marketplace_ids')}
										{form.renderButton('save', { loading: isLoading })}
									</div>
								</div>
							</div>
						</div>
					</>
				)
			}}
		></Page>
	)
}
