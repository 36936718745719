import mainStyles from '../../assets/css/App.module.scss'
import { Button, Loader } from '../../components'
import React, { useMemo } from 'react'
import { FormComponent } from '../../shared'
import { Link } from 'react-router-dom'
import { useLinkDispatch, useLinkState } from '../../contexts/link'

export const TrafficSourceAccountsList = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	const options = useMemo(() => {
		if (!linkState.isInitialized) return []
		if (!linkState.data.selectedTrafficSource) return []

		return linkState.data.link.trafficSourceAccounts.filter(
			trafficSourceAccount => {
				return (
					trafficSourceAccount.token ===
					linkState.data.selectedTrafficSource.value
				)
			}
		)
	}, [linkState.data.selectedTrafficSource])

	const form = useMemo(() => {
		const options = {
			traffic_source: {
				label: 'Traffic Source',
				props: {
					type: 'select',
					value: linkState.data.selectedTrafficSource,
					onChange: item => {
						linkDispatch({
							type: 'update',
							payload: {
								data: {
									...linkState.data,
									selectedTrafficSource: item
								}
							}
						})
					}
				},
				options: linkState.data?.link?.trafficSources?.map(trafficSource => ({
					value: trafficSource.token,
					label: trafficSource.name
				}))
			}
		}

		const form = new FormComponent(options)

		return <>{form.renderInput('traffic_source')}</>
	}, [linkState.data.link.trafficSources, linkState.data.selectedTrafficSource])

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ width: '100%' }}>{form}</div>
				<div>
					<span style={{ float: 'right' }}>
						<Button size="small" to="/traffic-source-accounts">
							Create
						</Button>
					</span>
				</div>
			</div>
			{options && options.length ? (
				<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
					<thead>
						<tr>
							<td>Name</td>
							<td>Account Identifier</td>
						</tr>
					</thead>
					<tbody className={mainStyles.smallFont}>
						{options.map(trafficSourceAccount => {
							return (
								<tr key={trafficSourceAccount.id}>
									<td className={mainStyles.overflow}>
										<Link
											className={mainStyles.link}
											to={`/traffic-source-accounts/${trafficSourceAccount.id}`}
										>
											{trafficSourceAccount.name}
										</Link>
									</td>
									<td className={mainStyles.noBreak}>
										{trafficSourceAccount.account_identifier}
									</td>
								</tr>
							)
						})}
						<tr />
					</tbody>
				</table>
			) : null}
			<Loader display={!linkState.isInitialized}></Loader>
		</>
	)
}
