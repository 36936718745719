import React, { useState } from 'react'
import styles from './Header.module.scss'
import { Link } from 'react-router-dom'
import Drawer from 'rc-drawer'
import history from '../../history'
import { StorageKeys, StorageUtil } from '../../shared'
import logo from '../../assets/images/logo-landscape.png'

export const Header = ({ authData }) => {
	const classes = [styles.header, styles.coloured]
	const [open, setOpen] = useState(false)

	function onDrawerTouchEnd() {
		setOpen(false)
	}

	function onDrawerSwitch() {
		setOpen(c => !c)
	}

	function handleLogout(e) {
		StorageUtil.setItem(StorageKeys.AUTH_DATA, null)
		history.push('/login')
		e.preventDefault()
	}

	function renderMenuItems() {
		const items = []

		if (authData) {
			if (authData.account.type === 'admin') {
				if (authData.account.permissions?.includes('reviewList')) {
					items.push(
						<li key="reviewList">
							<Link className="link" to={'/review/list'}>
								Books
							</Link>
						</li>
					)
				}
				if (authData.account.permissions?.includes('programList')) {
					items.push(
						<li key="programList">
							<Link className="link" to={'/program/list'}>
								Programs
							</Link>
						</li>
					)
				}
				if (authData.account.permissions?.includes('accountList')) {
					items.push(
						<li key="accountList">
							<Link className="link" to={'/account/list'}>
								App Accounts
							</Link>
						</li>
					)
				}
				if (authData.account.permissions?.includes('poliAccountList')) {
					items.push(
						<li key="poliAccountList">
							<Link className="link" to={'/poli/account/list'}>
								Poli Accounts
							</Link>
						</li>
					)
				}
			}

			if (authData.account.permissions?.includes('linkList')) {
				items.push(
					<li key="links">
						<Link className="link" to={'/app/links'}>
							Links
						</Link>
					</li>
				)
			}

			if (authData.account.permissions?.includes('reports')) {
				items.push(
					<li key="reports">
						<Link className="link" to={'/reports'}>
							Reports
						</Link>
					</li>
				)
			}

			if (authData.account.permissions?.includes('productsFunnels')) {
				items.push(
					<li key="traffic-sources">
						<Link className="link" to={'/products-funnels'}>
							Products & Funnels
						</Link>
					</li>
				)
			}

			if (authData.account.permissions?.includes('emailFix')) {
				items.push(
					<li key="emailFix">
						<Link className="link" to={'/email/fix'}>
							Email Fix
						</Link>
					</li>
				)
			}

			if (
				authData.account.permissions?.includes('crmLeads') ||
				authData.account.permissions?.includes('crmEventsTriggering')
			) {
				items.push(
					<li key="crm">
						<Link className="link" to={'/crm'}>
							CRM
						</Link>
					</li>
				)
			}

			items.push(
				<li key="validator">
					<Link className="link" to={'/review/validator'}>
						Validator
					</Link>
				</li>
			)

			if (authData.account.permissions?.includes('permissionManagement')) {
				items.push(
					<li key={'permissions'}>
						<Link className={'link'} to={'/account/permissions'}>
							Permissions
						</Link>
					</li>
				)
			}

			items.push(
				<li key="logout">
					<a href="#" className="link" onClick={handleLogout}>
						Logout
					</a>
				</li>
			)
		}

		return <ul>{items}</ul>
	}

	return (
		<div className={classes.join(' ')}>
			<div className={styles.wrapper}>
				<h1>
					<Link to={'/welcome'}>
						<img src={logo} alt="" className={styles.logo} />
					</Link>
				</h1>
				{authData ? (
					<button className={styles.menuIcon} onClick={onDrawerSwitch}>
						≡
					</button>
				) : null}
				<Drawer
					open={open}
					onClose={onDrawerTouchEnd}
					handler={false}
					level={null}
					placement="right"
					width={220}
				>
					{renderMenuItems()}
				</Drawer>
			</div>
		</div>
	)
}
