import { useReportDispatch, useReportState } from '../../contexts/report'
import { FormComponent } from '../../shared'
import { useLinkState } from '../../contexts/link'
import { useMemo } from 'react'

export const AccountInput = () => {
	const reportDispatch = useReportDispatch()
	const { data: reportData } = useReportState()

	const linkState = useLinkState()

	const options = useMemo(() => {
		return linkState.data.link.trafficSourceAccounts
			.filter(item => item.token === reportData.selectedTrafficSourceToken)
			.map(account => ({
				value: account.account_identifier,
				label: account.name
			}))
	}, [reportData])

	const form = new FormComponent({
		account: {
			label: 'Account',
			props: {
				type: 'select',
				value: reportData.selectedTrafficSourceAccount,
				onChange: item => {
					reportDispatch({
						type: 'update',
						payload: {
							data: {
								...reportData,
								selectedTrafficSourceAccount: item,
								selectedFunnel: null,
								selectedProduct: null,
								campaignFilter: null
							}
						}
					})
				}
			},
			options
		}
	})

	return form.renderInput('account')
}
