import React, { useEffect, useState } from 'react'
import { Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Account/Account.module.scss'
import { FormComponent } from '../../shared'
import { LinkUtil } from '../../shared/link'
import history from '../../history'
import { useLinkState } from '../../contexts/link'

export const Node = ({ route }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const linkState = useLinkState()

	useEffect(() => {
		if (!linkState.isInitialized) return

		const id = getNodeId()

		if (id) {
			setIsEditing(true)
			getNode(id).then(response => {
				setData(response)
				setIsLoading(false)
			})
		}
	}, [linkState.isInitialized])

	const getNodeId = () => {
		const id = route.match.params.id || null

		return !isNaN(id) ? parseInt(id) : null
	}

	const getNode = async id => {
		const linkUtil = new LinkUtil()
		setIsLoading(true)

		const { status: responseStatus, data } = await linkUtil.getNode(id)

		if (responseStatus !== 200) {
			toastMessage(data.error, 'error')
			return
		}

		const {
			link_node_id,
			link_funnel_id,
			name,
			funnelflux_id,
			type,
			domain,
			status
		} = data.data

		const funnel = findFunnel(link_funnel_id)

		return {
			link_node_id,
			link_funnel_id,
			name,
			funnelflux_id,
			type,
			domain,
			status,
			funnel
		}
	}

	function handleSave() {
		const linkUtil = new LinkUtil()
		const id = getNodeId()

		setIsLoading(true)

		const payload = {
			link_funnel_id: data.funnel.value,
			name: data.name,
			funnelflux_id: data.funnelflux_id,
			domain: data.domain,
			status: data.status,
			type: data.type
		}

		if (id) {
			linkUtil.updateNode(id, payload).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error || 'An error has been occurred', 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Node updated successfully', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		} else {
			linkUtil.createNode(payload).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.error, 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Node created successfully', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		}
	}

	function findFunnel(funnelId) {
		if (!funnelId || !linkState.isInitialized) {
			return {}
		}
		const index = linkState.data.link.funnels?.findIndex(
			funnel => funnel.id === parseInt(funnelId)
		)

		const funnel = linkState.data.link.funnels[index]

		return {
			value: funnel?.id,
			label: funnel?.name
		}
	}

	const statusOptions = [
		{ value: 'active', label: 'Active' },
		{ value: 'inactive', label: 'Inactive' }
	]

	const typeOptions = [
		{ value: 'regular', label: 'Regular' },
		{ value: 'checkout', label: 'Checkout' },
		{ value: 'app-launcher', label: 'App Launcher' }
	]

	const form = () => {
		if (!data) return

		const options = {
			funnel: {
				label: 'Funnel',
				props: {
					isDisabled: !!isEditing,
					type: 'select',
					value: data.funnel,
					onChange: e => {
						setData({
							...data,
							funnel: e
						})
					}
				},
				options: linkState.data.link.funnels?.map(funnel => ({
					value: funnel.id,
					label: funnel.name
				}))
			},
			name: {
				label: 'Name',
				props: {
					type: 'text',
					placeholder: 'Name',
					value: data.name,
					maxLength: 256,
					onChange: e => {
						setData({
							...data,
							name: e.target.value
						})
					}
				}
			},
			funnelflux_id: {
				label: 'Funnelflux ID',
				props: {
					type: 'text',
					placeholder: 'Funnelflux ID',
					value: data.funnelflux_id,
					maxLength: 256,
					onChange: e => {
						setData({
							...data,
							funnelflux_id: e.target.value
						})
					}
				}
			},
			domain: {
				label: 'Domain',
				props: {
					type: 'text',
					placeholder: 'Domain',
					value: data.domain,
					onChange: e => {
						setData({
							...data,
							domain: e.target.value
						})
					}
				}
			},
			type: {
				label: 'Type',
				props: {
					type: 'select',
					placeholder: 'Type',
					value: typeOptions.find(type => type.value === data.type),
					onChange: e => {
						setData({
							...data,
							type: e.value
						})
					}
				},
				options: typeOptions
			},
			status: {
				label: 'Status',
				props: {
					type: 'select',
					value: statusOptions.find(status => status.value === data.status),
					onChange: e => {
						setData({
							...data,
							status: e.value
						})
					}
				},
				options: statusOptions
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: handleSave
				}
			}
		}

		const form = new FormComponent(options)

		return (
			<>
				{form.renderInput('funnel')}
				{form.renderInput('name')}
				{form.renderInput('funnelflux_id')}
				{form.renderInput('type')}
				{form.renderInput('domain')}
				{form.renderInput('status')}
				{form.renderButton('save', { loading: isLoading })}
			</>
		)
	}

	return (
		<Page
			render={() => {
				if (!linkState?.isInitialized) {
					return <Loader display={true} />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								{isEditing ? (
									<h3>
										Editing Node #{getNodeId()}:{data?.name}
									</h3>
								) : (
									<h3>Creating Node</h3>
								)}
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>{form()}</div>
								</div>
							</div>
						</div>
						<Loader display={isLoading}></Loader>
					</>
				)
			}}
		></Page>
	)
}
