import React from 'react'
import { isEqual } from 'lodash'
import { LinkUtil } from '../shared/link'

const LinkStateContext = React.createContext()
const LinkDispatchContext = React.createContext()
const linkService = new LinkUtil()

function getData() {
	const linkDataKeys = [
		'accounts',
		'products',
		'funnels',
		'nodes',
		'urls',
		'trafficSources',
		'sources',
		'trafficSourceAccounts'
	]
	const linkData = {}

	return Promise.all([
		linkService.getAccounts(),
		linkService.getProducts(),
		linkService.getFunnels(),
		linkService.getNodes(),
		linkService.getUrls(),
		linkService.getTrafficSources(),
		linkService.getSources(),
		linkService.getTrafficSourceAccounts()
	]).then(values => {
		values.forEach((value, index) => {
			linkData[linkDataKeys[index]] = value
		})
		return linkData
	})
}

function initState(dispatch, state) {
	if (state?.isInitialized) return

	getData()
		.then(linkData => {
			dispatch({
				type: 'init',
				payload: {
					data: {
						link: linkData
					},
					instance: linkService,
					isInitialized: true
				}
			})
		})
		.catch(e => console.log(e))
}

function updateState(dispatch) {
	getData()
		.then(linkData => {
			dispatch({
				type: 'update',
				payload: {
					data: {
						link: linkData
					}
				}
			})
		})
		.catch(e => console.log(e))
}

function linkReducer(state, action) {
	const newState =
		action.type === 'init'
			? action.payload
			: {
					...state,
					data: {
						...state.data,
						...action.payload.data
					}
				}

	if (isEqual(state, newState)) return state

	switch (action.type) {
		case 'init':
		case 'update':
			return newState

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

function LinkProvider({ children }) {
	const [state, dispatch] = React.useReducer(linkReducer, {})
	return (
		<LinkStateContext.Provider value={state}>
			<LinkDispatchContext.Provider value={dispatch}>
				{children}
			</LinkDispatchContext.Provider>
		</LinkStateContext.Provider>
	)
}

function useLinkState() {
	const context = React.useContext(LinkStateContext)
	if (context === undefined) {
		throw new Error('useLinkState must be used within a LinkProvider')
	}
	return context
}

function useLinkDispatch() {
	const context = React.useContext(LinkDispatchContext)
	if (context === undefined) {
		throw new Error('useLinkDispatch must be used within a LinkProvider')
	}
	return context
}

export { LinkProvider, useLinkState, useLinkDispatch, initState, updateState }
