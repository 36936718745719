import styles from './ProductFunnels.module.scss'
import React from 'react'

export const TRAFFIC_SOURCE_TYPE_LIST = [
	{
		id: 'products',
		label: 'Products'
	},
	{
		id: 'funnels',
		label: 'Funnels'
	},
	{
		id: 'nodes',
		label: 'Nodes'
	},
	{
		id: 'traffic-sources',
		label: 'Traffic Sources'
	},
	{
		id: 'traffic-source-accounts',
		label: 'Traffic Source Accounts'
	}
]

export function StatusTd({ status, children }) {
	const classes = []

	if (status === 'active') {
		classes.push(styles.statusLight)
	} else {
		classes.push(styles.statusLight)
		classes.push(styles.inactive)
	}

	return <td className={classes.join(' ')}>{children}</td>
}
