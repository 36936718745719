import React from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { Button, Loader } from '../../components'
import { Link } from 'react-router-dom'
import { useLinkState } from '../../contexts/link'

export const TrafficSourceList = () => {
	const linkState = useLinkState()

	return (
		<>
			<span style={{ float: 'right' }}>
				<Button size="small" to="/traffic-sources">
					Create
				</Button>
			</span>
			{linkState.isInitialized &&
			linkState.data?.link?.trafficSources?.length ? (
				<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
					<thead>
						<tr>
							<td>Name</td>
							<td>Token</td>
						</tr>
					</thead>
					<tbody className={mainStyles.smallFont}>
						{linkState.data.link.trafficSources.map(trafficSource => {
							return (
								<tr key={trafficSource.id}>
									<td className={mainStyles.overflow}>
										<Link
											className={mainStyles.link}
											to={`/traffic-sources/${trafficSource.id}`}
										>
											{trafficSource.name}
										</Link>
									</td>
									<td className={mainStyles.noBreak}>{trafficSource.token}</td>
								</tr>
							)
						})}
						<tr />
					</tbody>
				</table>
			) : null}
			<Loader display={!linkState.isInitialized}></Loader>
		</>
	)
}
