import crmStyles from '../Crm/Crm.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { FormComponent, ReviewUtil } from '../../shared'
import { toastMessage } from '../../components'

export const RegenerateParagraphsModal = ({
	reviewId,
	bookTitle,
	bookSubtitle,
	paragraphs,
	closeModal
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [selectedParagraph, setSelectedParagraph] = useState(null)

	const reviewUtil = new ReviewUtil(reviewId)

	const onSubmit = useCallback(() => {
		setIsLoading(true)

		reviewUtil
			.regenerateParagraph(selectedParagraph.value)
			.then(({ data }) => {
				setIsLoading(false)
				toastMessage(data.message || 'Sequence placed in queue', 'success')
				history.push('/review/list')
			})
			.catch(() => {
				setIsLoading(false)
				toastMessage('Failed to regenerate sequence', 'error')

				closeModal()
			})
	}, [selectedParagraph])

	const form = useMemo(() => {
		const options = {
			paragraphs: {
				label: 'Paragraph',
				props: {
					type: 'select',
					value: selectedParagraph,
					onChange: e => {
						setSelectedParagraph(e)
					}
				},
				options: paragraphs.map((paragraph, index) => ({
					value: index + 1,
					label: paragraph
				}))
			},
			submit: {
				label: 'Regenerate Audio',
				props: {
					type: 'submit',
					className: crmStyles.modalButton,
					onClick: onSubmit
				}
			}
		}

		return new FormComponent(options)
	}, [selectedParagraph])

	return (
		<>
			<div>
				<h3 className={crmStyles.contactName}>{bookTitle}</h3>
				<h4 className={crmStyles.mobileNumber}>{bookSubtitle}</h4>
			</div>
			<div className={crmStyles.links}>
				{form.renderInput('paragraphs')}
				{form.renderButton('submit', { display: isLoading })}
			</div>
		</>
	)
}
