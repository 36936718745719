import React from 'react'
import { Forbidden, Page } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { TrafficSourceTypes } from './TrafficSourceTypes'
import { TrafficSourceOptions } from './TrafficSourceOptions'
import { useLinkDispatch, useLinkState } from '../../contexts/link'

export const ProductFunnels = () => {
	const isLoaded = true

	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	function handleTabChange(id) {
		linkDispatch({
			type: 'update',
			payload: {
				data: {
					...linkState.data,
					selectedTab: id
				}
			}
		})
	}

	function render(pageState) {
		if (!pageState.authData.account.permissions?.includes('productsFunnels')) {
			return <Forbidden />
		}

		return (
			<div className={mainStyles.container}>
				<div className={mainStyles.wrapper}>
					<h3>Products & Funnels</h3>
					<TrafficSourceTypes
						handleChange={handleTabChange}
						selectedTab={linkState.data?.selectedTab || 'products'}
					/>
					<TrafficSourceOptions
						pageState={pageState}
						selectedTab={linkState.data?.selectedTab || 'products'}
					/>
				</div>
			</div>
		)
	}

	return <Page render={render} isLoaded={isLoaded} />
}
