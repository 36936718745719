import React from 'react'
import { TRAFFIC_SOURCE_TYPE_LIST } from './utils'
import { TrafficSourceList } from './TrafficSourceList'
import { TrafficSourceAccountsList } from './TrafficSourceAccountsList'
import { ProductList } from './ProductList'
import { FunnelList } from './FunnelList'
import { NodeList } from './NodeList'

export const TrafficSourceOptions = ({ selectedTab }) => {
	switch (selectedTab) {
		case TRAFFIC_SOURCE_TYPE_LIST[0].id:
			return <ProductList />
		case TRAFFIC_SOURCE_TYPE_LIST[1].id:
			return <FunnelList />
		case TRAFFIC_SOURCE_TYPE_LIST[2].id:
			return <NodeList />
		case TRAFFIC_SOURCE_TYPE_LIST[3].id:
			return <TrafficSourceList />
		case TRAFFIC_SOURCE_TYPE_LIST[4].id:
			return <TrafficSourceAccountsList />
		default:
			return null
	}
}
