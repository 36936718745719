import React from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { Button, Loader } from '../../components'
import { Link } from 'react-router-dom'
import { useLinkState } from '../../contexts/link'

export const ProductList = () => {
	const linkState = useLinkState()

	return (
		<>
			<span style={{ float: 'right' }}>
				<Button size="small" to="/products/create">
					Create
				</Button>
			</span>
			{linkState.isInitialized && linkState.data.link?.products?.length ? (
				<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
					<thead>
						<tr>
							<td>Name</td>
							<td>PK</td>
						</tr>
					</thead>
					<tbody className={mainStyles.smallFont}>
						{linkState.data.link.products.map(product => {
							return (
								<tr key={product.id}>
									<td className={mainStyles.overflow}>
										<Link
											className={mainStyles.link}
											to={`/products/${product.id}`}
										>
											{product.name}
										</Link>
									</td>
									<td className={mainStyles.noBreak}>{product.pk}</td>
								</tr>
							)
						})}
						<tr />
					</tbody>
				</table>
			) : null}
			<Loader display={!linkState.isInitialized}></Loader>
		</>
	)
}
