import React, { useMemo } from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { Button, Loader } from '../../components'
import { Link } from 'react-router-dom'
import { StatusTd } from './utils'
import { useLinkDispatch, useLinkState } from '../../contexts/link'
import { FormComponent } from '../../shared'

export const FunnelList = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	const funnels = useMemo(() => {
		if (!linkState.isInitialized) return []
		if (!linkState.data.selectedProduct) return []

		return linkState.data.link.funnels
			.filter(funnel => {
				return funnel.productId === linkState.data.selectedProduct.value
			})
			.sort(a => {
				return a.status === 'active' ? -1 : 1
			})
	}, [linkState.data.selectedProduct])

	const options = {
		product: {
			label: 'Product',
			props: {
				type: 'select',
				value: linkState.data.selectedProduct,
				onChange: item => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								...linkState.data,
								selectedProduct: item
							}
						}
					})
				}
			},
			options: linkState.data?.link?.products?.map(product => ({
				value: product.id,
				label: product.name
			}))
		}
	}

	const form = new FormComponent(options)

	return (
		<>
			<span style={{ float: 'right' }}>
				<Button size="small" to="/funnels">
					Create
				</Button>
			</span>
			{form.renderInput('product')}
			{linkState.isInitialized && funnels.length ? (
				<>
					<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
						<thead>
							<tr>
								<td>Name</td>
								<td>FunnelFlux ID</td>
								<td>FK</td>
								<td>SFK</td>
							</tr>
						</thead>
						<tbody className={mainStyles.smallFont}>
							{funnels.map(funnel => {
								return (
									<tr key={funnel.id}>
										<StatusTd status={funnel.status}>
											<Link
												className={mainStyles.link}
												to={`/funnels/${funnel.id}`}
											>
												{funnel.name}
											</Link>
										</StatusTd>
										<td className={mainStyles.noBreak}>
											{funnel.funnelflux_id}
										</td>
										<td className={mainStyles.noBreak}>{funnel.fk}</td>
										<td className={mainStyles.noBreak}>{funnel.sfk}</td>
									</tr>
								)
							})}
							<tr />
						</tbody>
					</table>
				</>
			) : null}
			<Loader display={!linkState.isInitialized}></Loader>
		</>
	)
}
